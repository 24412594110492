.settingsSidebarContainer [name="settings-sidebar-item"]:not(:first-child):not(:last-child) {
  margin: 0;
}

.settingsSidebarContainer [name="settings-sidebar-item"]:first-child {
  margin-bottom: 0;
}

.settingsSidebarContainer [name="settings-sidebar-item"]:last-child {
  margin-top: 0;
}

/* .settingsSidebarContainer [name="settings-sidebar-item"]:hover {
  cursor: pointer;
  background: linear-gradient(to right, #c0d6df 50%, transparent 0%), linear-gradient(#c0d6df 50%, transparent 0%), linear-gradient(to right, #c0d6df 50%, transparent 0%), linear-gradient(#c0d6df 50%, transparent 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 8px 1px, 1px 8px;
} */
