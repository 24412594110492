.info__container {
  display: flex;
  flex-wrap: wrap;
}

.info__row {
  margin-bottom: 24px;
}

.info__title {
  font-weight: 700 !important;
}

.info__value {
  color: #a7a7a7;
}

.customer__card {
  background-color: #929da6;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.service__section {
  margin-top: 30px !important;
}

.service__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px !important;
}

.service__chip {
  background-color: #e5eddf;
  border-radius: 15px;
  padding: 0 10px;
  margin: 5px 8px 4px 0;
  color: black;
}

.comment__container {
  border: 1px dashed #c9c9c9;
  color: #424141;
  box-shadow: 0 0 10px -6px;
  margin-bottom: 20px;
  padding: 8px 16px;
  border-radius: 6px;
}
.comment__title {
  font-weight: 700 !important;
  color: #a7a7a7;
  margin-right: 10px;
}
.comment__secondary__title {
  color: #a7a7a7;
  margin-right: 10px;
}
.comment__field {
  width: 100%;
  margin-bottom: 16px !important;
  margin-top: 10px !important;
}

.comment__title__container {
  display: flex;
}

.divider {
  margin-bottom: 30px !important;
  margin-top: 25px !important;
}
