.container {
  background-color: red;
  position: fixed;
  bottom: 8rem;
  right: 2.5rem;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
  z-index: 999;
}

.titleContainer {
  display: flex;
  align-items: center;
  padding: 22px 30px !important;
}

.title {
  color: #363638;
  margin-left: 10px !important;
}

.progressContainer {
  display: flex;
  align-items: center;
  padding: 0 30px !important;
}

.progressBar {
  width: 100%;
  color: #1565c0;
}

.progress {
  color: #363638;
  margin-left: 50px !important;
}

.progress span {
  color: #1565c0;
}

.initialGuideContainer {
  padding: 10px 30px !important;
}
