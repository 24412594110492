.mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}