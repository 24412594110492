.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 100px;
  padding-top: 40px;
}

.right-sidebar {
  /* flex-basis: 500px; */
  /* flex-basis: 30%; */
  background-color: white;
}

/* @media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
} */
