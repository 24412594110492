.card__row {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
}

.card {
  margin-bottom: 20px;
}

.card__cotainer {
  padding: 18px;
}

.card__action__container {
  display: flex;
  justify-content: end;
  position: relative;
  left: 10px;
}
