#navbar-menu {
  color: #626c76;
  left: 0;
  margin-top: 5px;
}
#navbar-menu li {
  color: #626c76;
}
#navbar-menu li:hover {
  background-color: #e5eaf2;
  color: #2f3336;
}
