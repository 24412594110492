.MuiDataGrid-cell {
  outline: none !important;
}

.MuiAutocomplete-groupLabel {
  background-color: #EBEBEB !important;
  padding-left: 25px !important;
  font-weight: bold !important;
  color: #303439 !important;
}

.MuiAutocomplete-listbox::-webkit-scrollbar {
  display: none !important;
}

.MuiAutocomplete-listbox {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
