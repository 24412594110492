.container {
  padding-top: 2rem;
  width: 100%;
  height: 100%;
}

.page__header {
  font-size: 28px;
  font-weight: 600;
  padding-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.search__container {
  width: 100%;
  background-color: white;
  margin-bottom: 20px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.price__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service__container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.service__chip {
  background-color: #e5eddf;
  border-radius: 15px;
  padding: 0 10px;
  margin: 5px;
}
