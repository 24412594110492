.tabs__bar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 30px;
}

.tab {
  font-weight: 700;
  padding-bottom: 5px;
  cursor: pointer;
}

.coupon__button {
  background-color: #4b64e9;
  height: 35px;
  margin-left: 20px;
  padding: 0 2rem;
}
.coupon__text {
  height: 35px;
}

.input__container {
  margin-bottom: 40px;
}

.coupon__title {
  color: #a2a9c0;
  font-size: 12px;
}
.coupon__value {
  color: #4d66e9;
  font-weight: 700;
}
.coupon__row {
  border-bottom: 1px solid #e0e0e0;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
