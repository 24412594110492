.input {
  height: 100%;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  outline: none;
  color: #495057;
  padding-left: 15px;
}

.icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  border-color: #f4effe;
}

.icon {
  color: #1976D2;
  font-size: 32px !important;
}

.input-wrap {
  background-color: white;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  border: 2px solid #f4effe;
}
