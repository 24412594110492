.search__container {
  width: 100%;
  background-color: white;
  margin-bottom: 20px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.page__header {
  font-size: 28px;
  font-weight: 600;
  padding-top: 40px;
  margin-bottom: 8px;
}

.tabs {
  display: flex;
  margin-top: 2rem;
  border-bottom: #9387ed63 1px solid;
  width: 100%;
}

.tab {
  margin-right: 1rem;
  padding-bottom: 5px;
  color: #727171;
  font-weight: 700;
  cursor: pointer;
}
