.link {
    text-decoration: none;
}

.container {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    margin: 20px 0 !important;
}

.leftSideContainer {
    display: flex;
}

.numberContainer {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #A6AAAC;
    display: flex;
    justify-content: center;
    color: white;
    margin-right: 10px !important;
}

.doneNumberContainer {
    background-color: #1565c0;
}

.title { 
    color: #363638;
}

.checkIcon {
    color: #01C144;
}