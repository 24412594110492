/* * {
  box-sizing: border-box;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.editorClassName {
  border: 1px solid #f1f1f1;
  margin-bottom: 2rem;
  padding-left: 10px;
  min-height: 70px;
  resize: vertical;
}

.app-placeholder {
  font-size: 1rem;
  color: #A9A9AC;
  font: inherit;
  letter-spacing: inherit;
  box-sizing: content-box;
  -webkit-tap-highlight-color: transparent;
  display: block;
}


.PhoneInput {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  height: 38px;
  padding-left: 10px;
}

.PhoneInputInput {
  border: none;
  outline: none;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}

#once-per-person > .MuiCheckbox-colorPrimary.Mui-checked,
[name='modal-enabled'] > .MuiCheckbox-colorPrimary.Mui-checked {
  color: green !important;
}

#mark-as-busy-slot > .MuiCheckbox-colorPrimary.Mui-checked,
[name='modal-enabled'] > .MuiCheckbox-colorPrimary.Mui-checked {
  color: green !important;
}

#business-hours > .MuiCheckbox-colorPrimary.Mui-checked,
[name='modal-enabled'] > .MuiCheckbox-colorPrimary.Mui-checked {
  color: green !important;
}

.MuiTableSortLabel-icon {
  display: none !important;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrDateDisplayWrapper {
  display: none;
}

.react-datepicker__input-container input {
  border: 0;
  font-size: 14px;
  text-align: center;
  text-align: start;
  width: 70%;
  color: #495057 !important;
}

.react-datepicker__input-container input:focus {
  outline: none;
}

.quickSearch {
  border: 0;
  width: 100%;
  font-size: 14px;
}

.quickSearch:focus {
  outline: none;
}

.quickSearch::placeholder {
  color: #929da6;
  font-size: 14px;
}

.MuiGrid-root .MuiGrid-container .css-fbqjby-MuiGrid-root {
  padding: 10px;
}

.MuiGrid-root .MuiGrid-container .css-il7j46-MuiGrid-root {
  padding: 10px;
}

.MuiGrid-root .MuiGrid-container .css-6hi0ku-MuiGrid-root {
  row-gap: 5px;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.rmdp-shadow {
  box-shadow: none !important;
}

.rmdp-full-year {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
  grid-gap: 20px;
}

.rmdp-calendar {
  width: 45vw;
}

.rmdp-full-year > div {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1rem;
}

#holidays-settings .rmdp-calendar {
  width: 100%;
}

#holidays-settings .rmdp-wrapper {
  width: 100%;
}

#holidays-settings .rmdp-header-values {
  display: flex;
  align-items: center;
  font-weight: 500;
  text-align: center;
  height: 20px;
  color: #6c70dd;
  font-size: 21px;
}

@media only screen and (max-width: 899px) {
  .rmdp-calendar {
    width: 95vw;
  }
}

.MuiList-root > a > * {
  color:
    /*#898CA5*/ #626c76;
}

.MuiList-root > a:active > * {
  color: #ffffff !important;
}

ul.MuiList-root.MuiList-padding.css-h4y409-MuiList-root a:active {
  background-color: #5d5d7e !important;
}

.MuiList-root a:active .css-dkrrz6-MuiListItemIcon-root,
.MuiList-root a:active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff;
}

.MuiList-root
  a:active
  .MuiListItem-button.css-1rutfq4-MuiButtonBase-root-MuiListItem-root
  > * {
  color: #ffffff;
}

.MuiList-root
  a
  .MuiListItem-button.css-sahhii-MuiButtonBase-root-MuiListItem-root
  > * {
  color:
    /*#898CA5*/ #626c76;
}

/* Calender */

.calendar {
  padding: 4px;
  border-radius: 4px;
  margin: 15px 0 !important;
}

.calendar .calendar-header {
  width: 100% !important;
  margin-bottom: 20px !important;
  border: none !important;
}

.calendar .year-neighbor2,
.calendar .year-neighbor {
  display: none !important;
}

.calendar .year-title {
  font-weight: 500;
  text-align: center;
  height: 20px;
  width: auto;
  color: #6c70dd;
}

.calendar .months-container {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center;
  row-gap: 20px;
  column-gap: 20px;
}

.calendar .month-container {
  flex: 0 0 90%;
}

.calendar .month-name {
  padding: 10px;
  font-size: 14px;
}

.calendar .month {
  width: 100%;
  background: #fefefe;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  box-shadow: 0px 0px 21px -5px #ddd;
  height: 240px;
  border-collapse: collapse;
}

.calendar .week-names {
  border-bottom: 1px solid #e4eaf4;
}

.calendar tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.calendar .day-header {
  font-weight: 400;
  color: #cdced1;
  padding-top: 13px;
  text-align: center;
  width: 20px;
  height: 50px;
  border: none;
  font-size: 12px;
}

.calendar .old {
  background: 0 0;
  cursor: default;
}

.calendar td {
  text-align: center;
  width: 20px;
  height: 50px;
  border: none;
  font-size: 12px;
}

.calendar .day-start {
  border-radius: 25px;
  color: white;
}

.calendar .day-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  font-size: 14px;
}

.calendar thead {
  border-bottom: 1px solid #e4eaf4 !important;
}

.calendar table.month .month-title {
  color: black;
  padding: 5px;
}

@media (min-width: 600px) {
  .calendar .month-container {
    flex: 0 0 30%;
  }
}

@media (min-width: 1320) {
  .calendar .month-container {
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }
}

.MuiList-root > a > * {
  color:
    /*#898CA5*/ #626c76;
}

.MuiList-root > a:active > * {
  color: #ffffff !important;
}

ul.MuiList-root.MuiList-padding.css-h4y409-MuiList-root a:active {
  background-color: #5d5d7e !important;
}

.MuiList-root a:active .css-dkrrz6-MuiListItemIcon-root,
.MuiList-root a:active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff;
}

.MuiList-root
  a:active
  .MuiListItem-button.css-1rutfq4-MuiButtonBase-root-MuiListItem-root
  > * {
  color: #ffffff;
}

.MuiList-root
  a
  .MuiListItem-button.css-sahhii-MuiButtonBase-root-MuiListItem-root
  > * {
  color:
    /*#898CA5*/ #626c76;
}

.disabled-row {
  color: #aec0c8;
}

.disabled-row span[name='times-span'] {
  color: #aec0c8 !important;
  background-color: #ffeac6 !important;
}
